import pintrest_icon from './Pintrest.png';
function Footer(){
  const footer_icons = [
    {
      id: 1,
      title:'linkdin',
      icon_src: "https://s3.amazonaws.com/gopher-test/assets/LinkedIn.png",
      icon_href: "https://www.linkedin.com/company/jaiinfoway/",
    },
    {
      id: 2,
      title:'facebook',
      icon_src: "https://s3.amazonaws.com/gopher-test/assets/Facebook.png",
      icon_href: "https://www.facebook.com/JaiInfoway/",
    },
    {
      id: 3,
      title:'youtube',
      icon_src: "https://s3.amazonaws.com/gopher-test/assets/Youtube.png",
      icon_href: "https://www.youtube.com/@jaiinfoway7778",
    },
    {
      id: 4,
      title:'instagram',
      icon_src: "https://s3.amazonaws.com/gopher-test/assets/Instagram.png",
      icon_href: "https://www.instagram.com/jaiinfowayofficial/",
    },
    {
      id: 5,
      title:'pintrest',
      icon_src: pintrest_icon,
      icon_href: "https://in.pinterest.com/Jai_Infoway/",
    },
    {
      id: 6,
      title:'x',
      icon_src: "https://gopher-test.s3.amazonaws.com/assets/x.png",
      icon_href: "https://x.com/jaiinfoway",
    }

  ]
 return (
   <div style={{ maxWidth: "1300px" }} className="mx-auto text-left p-10">
  <div className="pb-3">
    <p className="text-sm font-semibold mb-5 text-gray-700">
      Miami (United States) <br />
      66 West Flagler Street, Suite #900-1140 <br />
      Miami, FL 33130 <br />
      info@jaiinfoway.com <br/>
      <span className="text-blue-600">+1 (412) 636-3349</span>
    </p>
  </div>
  <div className="pb-3">
    {footer_icons.map((icon) => {
      return (
        <a className="p-1" id={icon.id} href={icon.icon_href} target="blank" key={icon.id}>
          <img
            className="zoom-effect-icon inline-block md:h-8 md:w-8 h-7 w-7 mr-2"
            alt=""
            src={icon.icon_src}
          />
        </a>
      );
    })}
  </div>
  <span className='text-sm md:text-lg'>© 2024 Jaiinfoway All Rights Reserved.</span>
</div>
 )
}

export default Footer;