import React, { useEffect, useState } from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";
import { TextField, Button } from "@mui/material";
import Swal from "sweetalert2";
import axios from "axios";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 5,
};

function Form({ openFormPopup, setOpenFormPopup }) {
  const open = openFormPopup;
  const setOpen = setOpenFormPopup;
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    company_name: "",
    contact_no: "",
    designation: "",
  });

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const apiUrl = process.env.REACT_APP_API_URL;
  useEffect(() => {
    setFormData({
      name: "",
      email: "",
      company_name: "",
      contact_no: "",
      designation: "",
    });
  }, [open]);
  const handleChange = (e) => {
    console.log(formData, "uuuuuuuuuuuuuuu");

    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Basic validation check for required fields
    if (!formData.name || !formData.company_name) {
      Swal.fire({
        title: "Error!",
        text: "Name and Company Name are required fields.",
        icon: "error",
      });
      return;
    }

    try {
      formData.sources = "Jaiinfoway"
      axios
        .post(`${apiUrl}/dynamic/submit-from`, formData, {
          headers: {
            "pk-mail": "155b34258348e3f44c97a17a850a72a7",
          },
        })
        .then((response) => {
          // Handle successful response
          handleClose();
          Swal.fire({
            title: "Thank You!",
            text: "Your details have been successfully submitted. We will be in touch with you shortly.",
            icon: "success",
          });
        })
        .catch((error) => {
          console.error(error);
        });
    } catch (error) {
      console.error("There was an error submitting the form!", error);
      // Handle error
    }
  };

  return (
    <>
      <button
        onClick={handleOpen}
        style={{
          width: "250px",
          boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
          border: "2px solid #1E40AF",
          borderRadius: "30px",
        }}
        className="zoom-effect-icon mx-auto mt-[100px] bg-gradient-to-r from-green-400 via-blue-400 to-purple-500 hover:from-blue-500 hover:to-green-500 text-white font-bold py-3 px-6 mb-10 transition-transform transform hover:scale-105"
      >
        Get Free Consultation
      </button>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style} className="md:w-6/12 w-10/12">
            <Typography id="transition-modal-title" variant="h6" component="h2">
              Join Our AI Innovation Hub: Share Your Insights and Stay Updated!
            </Typography>
            <form onSubmit={handleSubmit}>
              <TextField
                id="outlined-name"
                name="name"
                label="Name"
                variant="outlined"
                fullWidth
                required
                sx={{
                  width: {
                    xs: "100%",
                    sm: "100%",
                    md: "80%",
                    lg: "80%",
                  },
                  mt: 2,
                }}
                value={formData.name}
                onChange={handleChange}
              />
              <TextField
                id="outlined-email"
                name="email"
                label="Email"
                variant="outlined"
                fullWidth
                sx={{
                  width: {
                    xs: "100%",
                    sm: "100%",
                    md: "80%",
                    lg: "80%",
                  },
                  mt: 2,
                }}
                type="email"
                value={formData.email}
                onChange={handleChange}
              />
              <TextField
                id="outlined-email"
                name="contact_no"
                label="Contact"
                variant="outlined"
                fullWidth
                sx={{
                  width: {
                    xs: "100%",
                    sm: "100%",
                    md: "80%",
                    lg: "80%",
                  },
                  mt: 2,
                }}
                type="number"
                value={formData.contact_no}
                onChange={handleChange}
              />
              <TextField
                id="outlined-company-name"
                name="company_name"
                label="Company Name"
                variant="outlined"
                fullWidth
                required
                sx={{
                  width: {
                    xs: "100%",
                    sm: "100%",
                    md: "80%",
                    lg: "80%",
                  },
                  mt: 2,
                }}
                type="text"
                value={formData.company_name}
                onChange={handleChange}
              />
              <TextField
                id="outlined-designation"
                name="designation"
                label="Designation"
                variant="outlined"
                fullWidth
                sx={{
                  width: {
                    xs: "100%",
                    sm: "100%",
                    md: "80%",
                    lg: "80%",
                  },
                  mt: 2,
                }}
                type="text"
                value={formData.designation}
                onChange={handleChange}
              />
              <Button
                type="submit"
                variant="contained"
                color="success"
                sx={{
                  mt: 4, // Add some top margin for spacing
                  mx: "auto", // Center the button horizontally
                  display: "block", // Ensure the button is centered
                }}
              >
                Submit
              </Button>
            </form>
          </Box>
        </Fade>
      </Modal>
    </>
  );
}

export default Form;
