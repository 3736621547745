import "./App.css";
import Form from "./Form";
import Footer from "./Footer";
import { useState } from "react";
import image1 from "./img1.png";
import about_us_img from "./about_us.jpg";
import jai from "./jai.jpg";

function App() {
  const [openFormPopup, setOpenFormPopup] = useState(true);
  return (
    <div style={{ maxWidth: "1200px" }} className="parent mx-auto">
      {/* hedder component */}
      <div className="mx-auto grid grid-cols-2 gap-4 md:gap-8 items-center">
        <div className="mt-5 md:ml-5 md:w-600px md:pl-10">
          <img
            decoding="async"
            className="w-auto h-auto"
            src="https://jaiinfoway.com/wp-content/uploads/2022/04/logo.png"
            title="TPx-logo-R-333-131px"
            alt="TPx-logo-R-333-131px"
            loading="lazy"
          />
        </div>
        <div className="text-center mt-4 md:mt-0 md:w-600px">
          <span style={{ color: "gray" }} className="font-bold">
            Free Consultation
          </span>
          <p className="font-bold text-md">+1 (412) 636-3349</p>
        </div>
      </div>

      {/* Image section */}
      <div className="md:mt-10 relative text-center py-20">
        <div className="absolute inset-0 bg-fixed bg-cover bg-center"></div>
        <div className="relative z-10">
          <span className="block md:text-8xl text-3xl mx-auto p-2 md:w-[800px] font-extrabold bg-clip-text bg-gradient-to-r from-black-400 to-black-600 hover:scale-110 transition duration-300 ease-in-out">
            We built Startup from scratch
          </span>

          <span className="md:w-[1000px] mx-auto p-2 md:pr-10 md:pl-10 block mt-5 md:text-lg text-md font-medium text-gray-500 animate-fade-in-up">
            Our mission is to help businesses succeed by transforming innovative
            ideas into market- ready products. With over 15 years of experience,
            we specialize in software development, digital marketing, and cloud
            services, ensuring your digital presence is impactful and effective.
          </span>
          <Form
            openFormPopup={openFormPopup}
            setOpenFormPopup={setOpenFormPopup}
          />
        </div>
      </div>
      <hr className="border-1 border-gray-800" />
      <div class="w-full md:p-10 p-5 mt-10 mb-10 rounded-lg border-blue-200">
        <div class="grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
          {/* <!-- Image Section --> */}
          <div class="flex items-center justify-center">
            <img
              src={image1}
              alt="Development Illustration"
              class="w-full h-auto rounded-lg shadow-lg"
            />
          </div>

          {/* <!-- Text Section --> */}
          <div class="text-center">
            <span class="bg-gradient-to-r from-blue-600 via-cyan-500 to-indigo-700 text-transparent bg-clip-text animate-gradient-flow transition-transform duration-500 md:text-4xl text-3xl font-bold">
              Accelerated development with Automated DevOps
            </span>
            <p class="mt-5 text-lg font-medium text-gray-500 transition-opacity duration-700">
              Transform your brand with cutting-edge solutions that accelerate
              growth, enhance visibility, and differentiate you in a competitive
              market. Achieve new heights with tailored strategies designed for
              success.
            </p>
          </div>
        </div>
      </div>
      <hr className="border-1 border-gray-800" />
      <div class="relative w-full h-auto p-4 mt-[100px] mb-[100px]">
        <div class="border-2 border-blue-500 rounded-xl overflow-hidden shadow-lg transition-all duration-500">
          <div class="relative w-full pb-[56.25%]">
            <iframe
              class="absolute top-0 left-0 w-full h-full"
              src="https://www.youtube.com/embed/64zBPIiBnoA?autoplay=1&si=xgAhekV702bJkA9e"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerpolicy="strict-origin-when-cross-origin"
              allowfullscreen
            ></iframe>
          </div>
        </div>
      </div>
      <div className="text-black p-8 md:p-16 mb-10">
        <h2 className="text-3xl md:text-4xl font-extrabold text-center text-transparent bg-gradient-to-r from-blue-500 via-teal-400 to-indigo-500 bg-clip-text mb-6">
          Why Choose Us?
        </h2>
        <p className="text-lg md:text-xl text-gray-600 text-center mb-8">
          With over 15 years of experience, Jaiinfoway combines deep expertise
          in generative AI, software development, and cloud services to deliver
          customized, secure, and scalable solutions. Our client-centric
          approach ensures we understand your vision and bring it to life with
          innovative, market-ready products.
        </p>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          <div className="flex flex-col items-center p-4 bg-gray-800 rounded-lg shadow-md w-full">
            <h3 className="text-xl font-bold mb-2 text-transparent bg-gradient-to-r from-blue-500 to-teal-400 bg-clip-text">
              Extensive Experience
            </h3>
            <p className="text-gray-400 text-center">
              Over 15 years of expertise in the industry.
            </p>
          </div>
          <div className="flex flex-col items-center p-4 bg-gray-800 rounded-lg shadow-md w-full">
            <h3 className="text-xl font-bold mb-2 text-transparent bg-gradient-to-r from-blue-500 to-teal-400 bg-clip-text">
              Generative AI Expertise
            </h3>
            <p className="text-gray-400 text-center">
              Cutting-edge AI solutions tailored to your needs.
            </p>
          </div>
          <div className="flex flex-col items-center p-4 bg-gray-800 rounded-lg shadow-md w-full">
            <h3 className="text-xl font-bold mb-2 text-transparent bg-gradient-to-r from-blue-500 to-teal-400 bg-clip-text">
              End-to-End Solutions
            </h3>
            <p className="text-gray-400 text-center">
              Comprehensive services from concept to deployment.
            </p>
          </div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 pt-10 lg:grid-cols-2 gap-8">
          <div className="flex flex-col items-center p-4 bg-gray-800 rounded-lg shadow-md w-full">
            <h3 className="text-xl font-bold mb-2 text-transparent bg-gradient-to-r from-blue-500 to-teal-400 bg-clip-text">
              Enterprise-Grade Security
            </h3>
            <p className="text-gray-400 text-center">
              Robust security measures for your peace of mind.
            </p>
          </div>
          <div className="flex flex-col items-center p-4 bg-gray-800 rounded-lg shadow-md w-full">
            <h3 className="text-xl font-bold mb-2 text-transparent bg-gradient-to-r from-blue-500 to-teal-400 bg-clip-text">
              Customization and Scalability
            </h3>
            <p className="text-gray-400 text-center">
              Solutions tailored to your needs and growth.
            </p>
          </div>
        </div>
      </div>
      {/* hagsha */}

      <div className="bg-gray-100 py-12">
        <div className="w-full px-8 py-8 max-w-7xl mx-auto">
          <h2 className="text-4xl font-bold text-gray-800 mb-12 text-center">
            Digital Presence
          </h2>

          <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
            <div className="group relative p-6 bg-gray-200 rounded-lg shadow-md hover:shadow-lg transition-shadow">
              <div className="flex justify-center mb-4">
                <span className="text-5xl">🌐</span>
              </div>
              <h3 className="text-xl font-semibold text-gray-700 text-center mb-4">
                Website Design
              </h3>
              <p className="text-gray-600 text-center">
                Your website is often your first point of contact with
                prospective customers. We consider both aesthetics and usability
                in our website designs and improve sales.
              </p>
            </div>

            <div className="group relative p-6 bg-gray-200 rounded-lg shadow-md hover:shadow-lg transition-shadow">
              <div className="flex justify-center mb-4">
                <span className="text-5xl">🎥</span>
              </div>
              <h3 className="text-xl font-semibold text-gray-700 text-center mb-4">
                Digital Marketing
              </h3>
              <p className="text-gray-600 text-center">
                Our digital marketing team will generate content and execute
                digital marketing tactics that attract consumers to your website
                and convert them into quality leads.
              </p>
            </div>

            <div className="group relative p-6 bg-gray-200 rounded-lg shadow-md hover:shadow-lg transition-shadow">
              <div className="flex justify-center mb-4">
                <span className="text-5xl">📊</span>
              </div>
              <h3 className="text-xl font-semibold text-gray-700 text-center mb-4">
                Data Analytics
              </h3>
              <p className="text-gray-600 text-center">
                As a data-driven marketing agency, we leverage advanced
                analytics and reporting to inform our strategies and to
                attribute results to specific marketing tactics.
              </p>
            </div>

            <div className="group relative p-6 bg-gray-200 rounded-lg shadow-md hover:shadow-lg transition-shadow">
              <div className="flex justify-center mb-4">
                <span className="text-5xl">⚙️</span>
              </div>
              <h3 className="text-xl font-semibold text-gray-700 text-center mb-4">
                SEO Optimization
              </h3>
              <p className="text-gray-600 text-center">
                By implementing an SEO campaign, your company gains a
                significant advantage. If your competitors, it's clear that your
                business will outshine them in rankings.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="flex flex-col md:flex-row items-center justify-center p-8">
        {/* <!-- Left Side: Image --> */}
        <div class="md:w-1/2">
          <img
            src={about_us_img}
            alt="Contact Us Illustration"
            class="w-full rounded-lg shadow-lg"
          />
        </div>

        {/* <!-- Right Side: Content --> */}
        <div class="md:w-1/2 text-center md:text-left mt-8 md:mt-0 md:ml-8">
          <div className=" flex items-center justify-center md:p-10 bg-white">
            <div>
              <div className="flex items-center mb-6">
                <img
                  src={jai}
                  alt="CEO"
                  className="w-20 h-20 rounded-full border-1 border-blue-500 mr-6"
                />
                <div>
                  <h3 className="text-xl font-bold text-gray-800">
                    CEO of Jaiinfoway
                  </h3>
                  <p className="text-lg font-semibold text-blue-600">
                    Jai Prakash
                  </p>
                </div>
              </div>
              <h2 className="text-3xl font-bold text-blue-600 mb-4">
                About Us
              </h2>
              <p className="text-gray-700 text-lg">
                Jaiinfoway is your partner in digital transformation, with over
                15 years of expertise in software development, digital
                marketing, and cloud services. We specialize in turning
                innovative ideas into market-ready products and are leaders in
                Generative AI, creating intelligent, AI-driven solutions that
                set you apart. Our mission is to deliver impactful results that
                drive your business forward, shaping your digital destiny with
                cutting-edge technology and expert guidance.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="mb-10">
        <div className="w-full max-w-6xl mx-auto p-10 bg-white rounded-2xl shadow-lg border border-gray-200">
          <h2 className="text-4xl font-extrabold text-gray-900 mb-16 text-center">
            Our Services
          </h2>

          <div className="flex flex-wrap justify-center gap-10">
            <div className="bg-white p-8 rounded-xl shadow-lg transform transition-transform hover:scale-105 hover:shadow-xl w-full sm:w-80">
              <h3 className="text-xl font-bold text-gray-800 mb-4 flex items-center">
                Web Application Development
              </h3>
              <ul className="text-gray-600 space-y-2 ml-4 list-disc">
                <li>Website Design</li>
                <li>SEO Optimization</li>
                <li>Digital Marketing</li>
                <li>Data Analytics</li>
              </ul>
            </div>

            <div className="bg-white p-8 rounded-xl shadow-lg transform transition-transform hover:scale-105 hover:shadow-xl w-full sm:w-80">
              <h3 className="text-xl font-bold text-gray-800 mb-4 flex items-center">
                Product Development
              </h3>
              <ul className="text-gray-600 space-y-2 ml-4 list-disc">
                <li>Ideation Towards Digital Transformation</li>
                <li>Product Strategy & Design Workshop</li>
                <li>UX & UI Design</li>
                <li>Client Input & Feedback</li>
              </ul>
            </div>

            <div className="bg-white p-8 rounded-xl shadow-lg transform transition-transform hover:scale-105 hover:shadow-xl w-full sm:w-80">
              <h3 className="text-xl font-bold text-gray-800 mb-4 flex items-center">
                Mobile Application Development
              </h3>
              <ul className="text-gray-600 space-y-2 ml-4 list-disc">
                <li>Integrated CRM</li>
                <li>Helpdesk/Service Ticketing</li>
                <li>Inventory</li>
                <li>Accounting</li>
              </ul>
            </div>

            <div className="bg-white p-8 rounded-xl shadow-lg transform transition-transform hover:scale-105 hover:shadow-xl w-full sm:w-80">
              <h3 className="text-xl font-bold text-gray-800 mb-4 flex items-center">
                Gen AI Solutions
              </h3>
              <ul className="text-gray-600 space-y-2 ml-4 list-disc">
                <li>Amazon Bedrock</li>
                <li>Google Vertex AI</li>
                <li>Microsoft Azure OpenAI</li>
              </ul>
            </div>

            <div className="bg-white p-8 rounded-xl shadow-lg transform transition-transform hover:scale-105 hover:shadow-xl w-full sm:w-80">
              <h3 className="text-xl font-bold text-gray-800 mb-4 flex items-center">
                Cloud Services
              </h3>
              <ul className="text-gray-600 space-y-2 ml-4 list-disc">
                <li>Google Cloud</li>
                <li>AWS</li>
                <li>Microsoft Azure</li>
              </ul>
            </div>

            {/* <!-- Optional: Additional cards can be added here --> */}
          </div>
        </div>
      </div>

      <hr className="border-1 border-gray-800" />

      {/* Footer */}
      <Footer></Footer>
    </div>
  );
}

export default App;
